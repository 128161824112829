import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'sla-svg-icon',
  standalone: true,
  imports: [NgClass],
  templateUrl: './svg-icon.component.html',
  styleUrl: './svg-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input() src: string | undefined; // -> Must be a material icon src (https://fonts.google.com/icons?selected=Material+Symbols+Rounded:check:FILL@1;wght@400;GRAD@0;opsz@24&icon.style=Rounded)
  @Input() svgClass: string | undefined;
}
